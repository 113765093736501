<template>
    <div class="task">
        <el-affix :offset="55">
            <header>
                <span class="head-but" @click="goback"><el-button plain :icon="ArrowLeft">返回</el-button></span>
                <span class="head-t">{{ title }}</span>
            </header>
        </el-affix>
        <div class="form">
            <el-row>
                <el-col :span="22" class="audit-title">
                    <span class="text">重大任务基本信息</span>
                </el-col>
            </el-row>
            <IndexInfo
                ref="indexRef"
                :id="route.query.id"
                :formData.sync="formData"
                :disabled="route.query.title == '查看重大任务'"
                @success="success"
            />
            <el-row>
                <el-col :span="22" class="audit-title button-title">
                    <span class="text">重大任务指标信息</span>
                    <span
                        ><el-button v-if="title !== '查看重大任务'" type="primary" @click="addIndex"
                            >新增指标</el-button
                        ></span
                    >
                </el-col>
                <el-col :span="22" class="table">
                    <el-table
                        :data="tableData"
                        :default-sort="{ prop: 'createTime', order: 'descending' }"
                        style="width: 100%; height: 100%"
                        header-row-class-name="table-header"
                        :header-cell-style="{
                            background: '#F7F8FA',
                            color: '#333333',
                            height: '54px',
                            'font-size': '14px',
                        }"
                    >
                        <el-table-column
                            type="index"
                            width="70"
                            label="序号"
                            class-name="el-table-fixed-column--left"
                        />
                        <el-table-column
                            v-for="item in tableColumns"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :formatter="item.formatter"
                            :show-overflow-tooltip="true"
                            :class-name="item.class"
                        >
                        </el-table-column>
                        <el-table-column label="操作" width="" v-if="title !== '查看重大任务'" class-name="textCenter">
                            <template #default="scope">
                                <el-button type="text" size="small" @click="editRow(scope.row, scope.$index)"
                                    >编辑</el-button
                                >
                                <el-button type="text" size="small">
                                    <el-popconfirm title="确定删除吗？" @confirm="handleDel(scope.row, scope.$index)">
                                        <template #reference>
                                            <span style="color: red" slot="reference">删除</span>
                                        </template>
                                    </el-popconfirm>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <el-row v-if="title !== '新增重大任务'">
                <el-col :span="22" class="audit-title">
                    <span class="text">审核记录</span>
                </el-col>
                <el-col :span="22" class="table">
                    <AuditRaecords :id="route.query.id" :type="4" />
                </el-col>
            </el-row>
        </div>
        <el-row :span="22" class="footer" v-if="title !== '查看重大任务'">
            <el-button @click="goback">返 回</el-button>
            <el-button type="primary" @click="saveItem(0)">保存为草稿</el-button>
            <el-button type="primary" @click="saveItem(1)">保存并提交审核</el-button>
        </el-row>
        <AddIndexs
            v-if="showAddIndex"
            :formData="indexFormData"
            :title="indexTitle"
            @success="indexSuccess"
            @close="showAddIndex = false"
        />
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance, nextTick } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AuditRaecords from '@/components/auditRaecords';
import IndexInfo from '@/components/indexInfo';
import IndexAuditTable from '@/views/TaskReview/components/indexAuditTable';
import indexsConfig from '@/views/taskManagement/components/indexsConfig';
import AddIndexs from '@/views/taskManagement/components/addIndexs';
let store = useStore();
const { proxy } = getCurrentInstance();

let userRole = ref(false);
let rules = {
    indexName: [{ required: true, message: '请输入指标名称', trigger: 'blur' }],
    indexType: [{ required: true, message: '请选择是否有目标值', trigger: 'blur' }],
    indexNum: [{ required: true, message: '请填写目标值', trigger: 'blur' }],
    sourceName: [{ required: true, message: '请输入数源应用名称', trigger: 'blur' }],
    whetherSource: [{ required: true, message: '请选择是否有数源应用', trigger: 'blur' }],
    indexSecret: [{ required: true, message: '请选择指标是否涉密', trigger: 'blur' }],
    unitId: [{ required: true, message: '请选择责任单位', trigger: 'blur' }],
    indexType: [{ required: true, message: '请输入目标值', trigger: 'blur' }],
    noIndexType: [{ required: false, message: '请输入目标值', trigger: 'blur' }],
};
let roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    if (title.value == '编辑指标') {
        role.forEach((e) => {
            if (roles.indexOf(e) > -1) {
                res = false;
            }
            return res;
        });
    } else {
        res = false;
    }
    return res;
}
const leadOptions = ref([]);
const router = useRouter();
let formLabelWidth = '150px';
let tableColumns = computed(() => {
    let main = indexsConfig['indexColunm'];
    return main;
});
const options = [
    {
        value: '1',
        label: '每月',
    },
    {
        value: '2',
        label: '每季度',
    },
    {
        value: '3',
        label: '每半年',
    },
    {
        value: '4',
        label: '每年',
    },
];
// const defaultProps = {
//     lazy: true,
//     checkStrictly: true,
//     multiple: true,
//     async lazyLoad(node, resolve) {
//         // console.log(node,);
//         const { level } = node;
//         let data = level > 0 ? node.value : 'top';
//         level + 1;
//         let res = await axios({
//             url: 'front/sysOrganization/getPage',
//             method: 'get',
//             params: {
//                 uacDeptId: data,
//             },
//         }); //.then(  => {
//         if (res) {
//             let nodes = res.map(item => {
//                 return {
//                     label: item.deptName,
//                     value: item.uacDeptId,
//                     leaf: false,
//                     deptId: item.deptId,
//                 };
//             });
//             if (res.length == 0) {
//                 console.log(node);
//                 node.data.leaf = true;
//             }
//             resolve(nodes);
//         }
//         // });
//     },
// };
// 点击联选节点触发事件
function handleChange(index, unitIndex, val) {
    console.log(index, unitIndex);
}
let tableData = ref([]);
let formData = ref({});
let indexFormData = ref({});
let frontTemplateNode = ref({});
let emit = defineEmits();
// let props = defineProps(['formData', 'title']);
// const { formData, title } = props;
// console.log(formData, title);
const route = useRoute();
let title = ref('');
let date = new Date();
let year = date.getFullYear();
let month = date.getMonth();
const form = ref({});
let showAddIndex = ref(false);
let indexTitle = ref('');
let indexList = ref([]);
let indexRef = ref(null);

// 表单数据初始化
function init() {
    title.value = route.query.title;
    form.value.actionId = route.query.id;
}
const getIndexById = () => {
    //根据指标id获取指标详情
    route.query.indexId &&
        proxy.$api.frontIndex.getById(route.query.indexId).then((res) => {
            if (res.code == 200) {
                form.value = res.data;
            }
        });
};
const getIndexesByActionId = () => {
    //根据id获取指标列表
    route.query.id &&
        proxy.$api.frontIndex.getIndexesByActionId(route.query.id).then((res) => {
            if (res.code == 200) {
                tableData.value = res.data;
                tableData.value.forEach((e) => {
                    e.indexTypeText = e.indexType == '1' ? '否' : '是';
                    e.indexSecretText = e.indexSecret == '1' ? '否' : '是';
                });
            }
        });
};
const getTaskById = () => {
    //根据id获取任务详情
    route.query.id &&
        proxy.$api.frontAction.getById(route.query.id).then((res) => {
            if (res.code == 200) {
                formData.value = res.data;
                frontTemplateNode.value = res.data.frontTemplateNode;
            }
        });
};
function getOption() {
    axios({
        url: '/system/dict/data/list',
        method: 'get',
        params: {
            dictType: 'lead_dept',
        },
    }).then((res) => {
        axios({
            url: '/system/dept/getUserByDeptId',
            method: 'get',
            params: {
                deptId: res[0].dictValue,
            },
        }).then((ret) => {
            leadOptions.value = ret;
        });
    });
}
function success(formData) {
    form.value = formData;
}
//提交并审核
const saveItem = async (status) => {
    nextTick(() => {
        indexRef.value.saveItem().then((res) => {
            console.log(form.value);
            let newForm = Object.assign({}, form.value, {
                frontIndexList: tableData.value,
            });
            if (status == 0) {
                //保存为草稿
                if (newForm.actionStatus == 1) {
                    //任务若不是草稿状态先判断指标状态
                    proxy.$api.frontIndex
                        .getIndexesNumberNotDraftAndVerifyBackByActionId(newForm.actionId)
                        .then((res) => {
                            if (res.data == 0) {
                                postItem(newForm, status);
                            } else {
                                ElMessage({
                                    message: '该任务已分解指标并提交审批，请驳回后再保存为草稿。',
                                    type: 'warning',
                                });
                                return;
                            }
                        });
                } else {
                    postItem(newForm, status);
                }
            } else {
                //保存并提交审核须校验所有必填项
                if (res) {
                    postItem(newForm, status);
                }
            }
        });
    });
};
function postItem(newForm, status) {
    console.log(newForm);
    // return;
    newForm.actionStatus = status;
    if (status == 1) {
        proxy.$api.frontAction
            .addAndUpdateToVerifying(newForm)
            .then((res) => {
                if (res.code == 200) {
                    ElMessage.success('成功');
                    router.push({
                        path: 'reportOnTheMission',
                        query: {
                            id: newForm.actionId,
                        },
                    });
                }
            })
            .catch((err) => {
                ElMessage.error(err);
            });
    } else {
        if (title.value == '编辑重大任务') {
            proxy.$api.frontAction
                .update(newForm)
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage.success('编辑成功');
                        router.push({
                            path: 'reportOnTheMission',
                            query: {
                                id: newForm.actionId,
                            },
                        });
                    }
                })
                .catch((err) => {
                    ElMessage.error(err);
                });
        } else {
            proxy.$api.frontAction
                .add(newForm)
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage.success('新增成功');
                        router.push({
                            path: 'reportOnTheMission',
                            query: {
                                id: newForm.actionId,
                            },
                        });
                    }
                })
                .catch((err) => {
                    ElMessage.error(err);
                });
        }
    }
}
function indexSuccess(formData) {
    console.log(formData, formData.index);
    if (JSON.stringify(formData) !== '{}') {
        if (formData.index) {
            tableData.value.splice(formData.index - 1, 1, formData);
        } else {
            tableData.value.push(formData);
            tableData.value.forEach((e) => {
                e.indexTypeText = e.indexType == '1' ? '否' : '是';
                e.indexSecretText = e.indexSecret == '1' ? '否' : '是';
            });
        }
    } else {
        getIndexesByActionId();
    }
    showAddIndex.value = false;
}
function handleDel(row, index) {
    if (title.value == '新增重大任务') {
        tableData.value.splice(index, 1);
    } else {
        proxy.$api.frontIndex.delete(row.indexId).then((res) => {
            ElMessage({
                message: '删除成功',
                type: 'success',
            });
            getIndexesByActionId(row.actionId);
        });
    }
}
function addIndex() {
    if (title.value == '编辑重大任务') {
        indexFormData.value = {
            actionId: formData.value.actionId,
        };
    }
    indexTitle.value = '新增指标';
    showAddIndex.value = true;
}
function editRow(row, index) {
    showAddIndex.value = true;
    indexFormData.value = { ...row, index: index + 1 };
    indexTitle.value = '编辑指标';
}
function goback() {
    router.push({
        path: '/reportOnTheMission',
        query: {
            id: form.value.actionId,
        },
    });
}
onMounted(() => {
    getTaskById();
    getIndexesByActionId();
    init();
    let formBox = document.getElementsByClassName('form');
    if (route.query.title.indexOf('查看') > -1) {
        formBox[0].style.height = 'calc(100% - ' + 100 + 'px)';
    }
    // getIndexById();
    // getOption();
});
</script>

<style scoped lang="stylus">
.task
    height: calc(100% - 32px);
    header
        // width: 1200px;
        height: 56px;
        line-height: 56px
        text-align: center
        background: #FFFFFF;
        box-shadow: 0 0 10px -6px rgba(0,0,0,0.30);
        border-radius: 4px;
        padding-left 24px
        border-bottom: 1px solid #EAEAEA
        .head-but
            float left
        .head-t
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
    .form
        height: calc(100% - 165px)
        .footer
            height: 64px;
            justify-content: center
            border-top 1px solid #EEEEEE
            align-items: center !important
            /deep/ .el-form-item
                margin-bottom 0
    .title
        text-align: left
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px
    .add-unit
        margin-bottom 24px
        color: #1492FF;
        text-align center
    .unit-input
        position relative
    .icon
        width: 16px;
        height: 16px;
        position absolute
        top 8px
        right -25px
        background: url('~@/assets/img/delete.png') no-repeat
    .tagertNum
        /deep/ .el-form-item__content
            flex-wrap: nowrap
            justify-content: space-between
    .table
        margin-bottom: 16px
    .button-title
        height 54px
        align-items: center
        .text
            width 100% !important
            margin-bottom: 0
        span
            &:nth-child(2)
                margin-bottom 10px
/deep/ .el-cascader
    width 39.2vw
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-row
    justify-content center
</style>
